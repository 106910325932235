// standard shades
$white: #FFFFFF;
$ebony: #020307;

// action color
$royal: #4C5AEA;
$royal-light: rgba(76, 90, 234, 0.2);
$hover: #707BEE;

// primary color
$glacier: #F8FBFD;
$astronaut: #272E78;
$bunting: #1A1E4F;
$cloud-burst: #202561;

// secondary color
$whisper: #EFEFF7;
$dusk: #B9BBD2;
$sapphire: #343DA0;
$linear-blue: linear-gradient(141.99deg, #4C5AEA 0.85%, #202561 96.65%);
$moonlight: #FFFBF0;
$sunshine: #FEE770;
$mochi: #EAF6ED;
$mantis: #76C673;
$green: #008000;
$yellow: #FEE770;
$aqua-haze: #F8FBFD;

// grays color
$mercury: #E9E9E9;
$santas: #A2A3AB;
$modal: rgba(0, 2, 25, 0.56);
$midgray: #5E5F68;

// success
$mistletoe: #008450;
$mistletoe-light: rgba(0, 132, 80, 0.1);

// warning/warm
$mustard: #A38900;
$mustard-light: rgba(163, 137, 0, 0.1);
$midgray-light: rgba(94, 95, 104, 0.1);

// error
$crimson: #B62929;
$crimson-light: rgba(182, 41, 41, 0.1);
$rose: #F4DFDF;
$red: #dc3545;
