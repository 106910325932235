/*#region font family*/

@font-face {
  font-family: 'Graphik Web';
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  src: url(../../app/assets/fonts/Graphik-Black-Web.woff2) format("woff2"), url(../../app/assets/fonts/Graphik-Black-Web.woff) format("woff");
}

@font-face {
  font-family: 'Graphik Web';
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  src: url(../../app/assets/fonts/Graphik-Bold-Web.woff2) format("woff2"), url(../../app/assets/fonts/Graphik-Bold-Web.woff) format("woff");
}

@font-face {
  font-family: 'Graphik Web';
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  src: url(../../app/assets/fonts/Graphik-Semibold-Web.woff2) format("woff2"), url(../../app/assets/fonts/Graphik-Semibold-Web.woff) format("woff");
}

@font-face {
  font-family: 'Graphik Web';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url(../../app/assets/fonts/Graphik-Regular-Web.woff2) format("woff2"), url(../../app/assets/fonts/Graphik-Regular-Web.woff) format("woff");
}

@font-face {
  font-family: 'Source Code Pro';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url(../../app/assets/fonts/SourceCodePro-Regular.woff2) format("woff2"), url(../../app/assets/fonts/SourceCodePro-Regular.woff) format("woff");
}

@font-face {
  font-family: 'Source Code Pro';
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  src: url(../../app/assets/fonts/SourceCodePro-Semibold.woff2) format("woff2"), url(../../app/assets/fonts/SourceCodePro-Semibold.woff) format("woff");
}

@font-face {
  font-family: 'Source Code Pro';
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  src: url(../../app/assets/fonts/SourceCodePro-Bold.woff2) format("woff2"), url(../../app/assets/fonts/SourceCodePro-Bold.woff) format("woff");
}

/*#endregion*/

:root {
  font-size: 100%
}

h1 {
  font-size: 2.5rem;
  line-height: 3.25rem;
}

h2 {
  font-size: 2.25rem;
  line-height: 2.75rem;
}

h3, h4 {
  font-size: 1.5rem;
  line-height: 2rem;
}

@media only screen and (max-width: 833px) {
  h3, h4 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

@media only screen and (min-width: 833px) and (max-width: 1099px) {
  h3, h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media only screen and (min-width: 1099px) and (max-width: 1439px) {
}

.larger, h5, .large {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

p, h6 {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.small, .smaller {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.caption {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
}

.semi_bold {
  font-weight: 500;
}

h1, h2, h3, h4, .bold {
  font-weight: 600;
}

.bolder {
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6, p, .small, .smaller, .caption, .larger {
  letter-spacing: 0rem;
}

.overline {
  font-weight: 600;
  letter-spacing: 0.5px;
}
