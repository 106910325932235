@import '_variables.scss';

/*#region color*/

.white_color {
  color: $white;
}

.ebony_color {
  color: $ebony;
}

.royal_color {
  color: $royal;
}

.royal_light_color {
  color: $royal-light;
}

.hover_color {
  color: $hover;
}

.glacier_color {
  color: $glacier;
}

.astronaut_color {
  color: $astronaut;
}

.bunting_color {
  color: $bunting;
}

.cloud_burst_color {
  color: $cloud-burst;
}

.whisper_color {
  color: $whisper;
}

.dusk_color {
  color: $dusk;
}

.sapphire_color {
  color: $sapphire;
}

.linear_blue_color {
  color: $linear-blue;
}

.moonlight_color {
  color: $moonlight;
}

.sunshine_color {
  color: $sunshine;
}

.mochi_color {
  color: $mochi;
}

.mantis_color {
  color: $mantis;
}

.green_color {
  color: $green;
}

.yellow_color {
  color: $yellow;
}

.aqua_haze_color {
  color: $aqua-haze;
}

.mercury_color {
  color: $mercury;
}

.santas_color {
  color: $santas;
}

.modal_color {
  color: $modal;
}

.midgray_color {
  color: $midgray;
}

.mistletoe_color {
  color: $mistletoe;
}

.mistletoe_light_color {
  color: $mistletoe-light;
}

.mustard_color {
  color: $mustard;
}

.mustard_light_color {
  color: $mustard-light;
}

.midgray_light_color {
  color: $midgray-light;
}

.crimson_color {
  color: $crimson;
}

.crimson_light_color {
  color: $crimson-light;
}

.rose_color {
  color: $rose;
}

.red_color {
  color: $red;
}

/*#endregion*/

/*#region bgcolor*/

.white_bgcolor {
  background: $white;
}

.ebony_bgcolor {
  background: $ebony;
}

.royal_bgcolor {
  background: $royal;
}

.royal_light_bgcolor {
  background: $royal-light;
}

.hover_bgcolor {
  background: $hover;
}

.glacier_bgcolor {
  background: $glacier !important;
}

.astronaut_bgcolor {
  background: $astronaut;
}

.bunting_bgcolor {
  background: $bunting;
}

.cloud_burst_bgcolor {
  background: $cloud-burst;
}

.whisper_bgcolor {
  background: $whisper;
}

.dusk_bgcolor {
  background: $dusk;
}

.sapphire_bgcolor {
  background: $sapphire;
}

.linear_blue_bgcolor {
  background: $linear-blue;
}

.moonlight_bgcolor {
  background: $moonlight;
}

.sunshine_bgcolor {
  background: $sunshine;
}

.mochi_bgcolor {
  background: $mochi;
}

.mantis_bgcolor {
  background: $mantis;
}

.green_bgcolor {
  background: $green;
}

.yellow_bgcolor {
  background: $yellow;
}

.aqua_haze_bgcolor {
  background: $aqua-haze;
}

.mercury_bgcolor {
  background: $mercury;
}

.santas_bgcolor {
  background: $santas;
}

.modal_bgcolor {
  background: $modal;
}

.midgray_bgcolor {
  background: $midgray;
}

.mistletoe_bgcolor {
  background: $mistletoe;
}

.mistletoe_light_bgcolor {
  background: $mistletoe-light;
}

.mustard_bgcolor {
  background: $mustard;
}

.mustard_light_bgcolor {
  background: $mustard-light;
}

.midgray_bgcolor {
  background: $midgray;
}

.midgray_light_bgcolor {
  background: $midgray-light;
}

.crimson_bgcolor {
  background: $crimson;
}

.crimson_light_bgcolor {
  background: $crimson-light;
}

.rose_bgcolor {
  background: $rose;
}

.red_bgcolor {
  background: $red;
}

/*#endregion*/
